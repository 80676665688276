(function(){
'use strict';

angular.module('classy')
// Provider to resolve Optimizely's SDK
.provider('$optimizely', function () {
  this.$get = function () {
    if (!this.hasSrc && !window.optimizely) {
      // Create Optimizely <script>
      var scriptEl = document.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.async = true;
      scriptEl.src = 'https://cdn.optimizely.com/js/20864580600.js';
      // Add Optimizely <script> to document
      var firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(scriptEl, firstScriptTag);

      this.hasSrc = true;
    }

    return window.optimizely;
  };
}).service('Optimizely', ["$log", "$injector", "EG_EVENTS", function ($log, $injector, EG_EVENTS) {
  this.isInit = false;
  var optimizely = void 0;

  this.init = function () {
    if (!this.isInit) {
      optimizely = $injector.get('$optimizely');
      this.isInit = true;
    }
  };

  /*
   * Example code:
   * window["optimizely"].push({
   *   "type": "event",
   *   "eventName": "watchedVideo",
   *   "tags": {
   *     "title": "Funny Cats",
   *     "duration": 30,
   *     "revenue": 5000
   *   }
   * });
   */
  this.track = function (name, data) {
    if (!optimizely || !optimizely.push) {
      $log.warn('[Optimizely:track] Optimizely not found');
      return;
    }

    if (!name) {
      $log.warn('[Optimizely:track] Optimizely event name required');
      return;
    }

    /* 
      TODO:
      move logic to eg.services.messenger after all services are lazy injected
      and we no longer rely on the open event as a virtual page view event
      https://classydev.atlassian.net/browse/CL-25698 */
    if (name === EG_EVENTS.open.modal || name === EG_EVENTS.open.inline) {
      $log.debug('[Optimizely:track] Optimizely open event');
      return;
    }

    // data is optional
    if (_.isEmpty(data)) {
      $log.info('[Optimizely:track] Optimizely sending event... ', name);
      optimizely.push({
        type: 'event',
        eventName: name
      });
    } else {
      $log.info('[Optimizely:track] Optimizely sending event... ', name, data);
      optimizely.push({
        type: 'event',
        eventName: name,
        tags: data
      });
    }
  };

  // recordPageView(options)
  // identifyMember(id, data)
  // eventBeacon(data)
}]);
})();