(function(){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/*
This controller is the parent controller of:
- eg.controllers.donation.js
- eg.controllers.faq.js
- eg.controllers.thank-you.js
Thus, they inherit to everything on $scope defined here.
*/
angular.module('classy').controller('EmbeddedGivingCtrl', ["CHANNEL", "EG_EVENTS", "cpConstants", "$log", "$sce", "$scope", "$rootScope", "$timeout", "scSourceCodes", "$q", "$window", "bugsnagClient", "PayPalSDK", "formDirective", "scAnalytics", "scBanner", "scCampaignsService", "scDigitalWalletsService", "recaptchaService", "EmbeddedGiving", "EmbeddedGivingAnalytics", "EmbeddedGivingColors", "EmbeddedGivingDAL", "EmbeddedGivingLocations", "EmbeddedGivingMessenger", "EmbeddedGivingPaths", "EmbeddedGivingRecurringNudge", "EmbeddedGivingUtil", function (CHANNEL, EG_EVENTS, cpConstants, $log, $sce, $scope, $rootScope, $timeout, scSourceCodes, $q, $window, bugsnagClient, PayPalSDK, formDirective, scAnalytics, scBanner, scCampaignsService, scDigitalWalletsService, recaptchaService, EmbeddedGiving, EmbeddedGivingAnalytics, EmbeddedGivingColors, EmbeddedGivingDAL, EmbeddedGivingLocations, EmbeddedGivingMessenger, EmbeddedGivingPaths, EmbeddedGivingRecurringNudge, EmbeddedGivingUtil) {
  EmbeddedGivingMessenger.init($scope, $scope.$state); // How we communicate with the iframe
  EmbeddedGivingColors.init($scope.theme);
  $scope.EmbeddedGivingDAL = EmbeddedGivingDAL;
  $scope.EmbeddedGivingPaths = EmbeddedGivingPaths;

  // $timeout(() => {
  //   window.postMessage('embedded-giving:modal:track-event:open');
  // }, 1000);

  /* Think of $scope.eg as "state" for EG controllers */
  $scope.eg = {
    activeView: EmbeddedGiving.activeView.path,
    donationStep: 'root',
    primaryColor: EmbeddedGivingColors.getPrimary(),
    accentColor: EmbeddedGivingColors.getAccent(),
    logoUrl: function logoUrl() {
      return EmbeddedGivingUtil.getLogoUrl($scope.page, $scope.theme);
    },

    isPreviewing: EmbeddedGiving.isPreviewing,
    isCheckingOut: false,
    isFOTEnabled: scCampaignsService.active.current.classy_mode_enabled || scCampaignsService.active.current.dcf_enabled,
    isDCFEnabled: scCampaignsService.active.current.dcf_enabled,
    isRequiredFullAddress: EmbeddedGiving.opts.eg.fullAddress,
    isRequiredRecurringNudge: EmbeddedGiving.opts.eg.recurringNudge,
    isAnonymousDonationAmountsEnabled: $scope.campaign.current.hide_donation_amounts,
    isBillingAddressAutocompleteEnabled: $scope.block && $scope.block.billing_address_autocomplete,
    isValidPayment: false,
    // For new version of FAQ that "overlays"
    faqOverlay: {
      isOpen: false,
      isOpened: function isOpened() {
        return $scope.eg.faqOverlay.isOpen;
      }
    },
    // Double the donation
    dtd: {
      enabled: false, // Does the org have dtd?
      active: false // DtD Checkbox
    },
    dcfMessage: function dcfMessage() {
      return $sce.trustAsHtml($scope.campaign.current.classy_mode_appeal);
    },
    showAchTerms: function showAchTerms() {
      return $scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.ACH;
    },

    showDonationConfirmation: false,
    showIsCompanyCard: function showIsCompanyCard() {
      return scCampaignsService.active.current.corporate_donation_enabled && ($scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.STRIPE || $scope.API.getPaymentMethod() === cpConstants.PAYMENT_METHODS.CC);
    }
  };

  // Not fully wired up currently...
  EmbeddedGivingDAL.initCache({
    amount: 1,
    isFeeOnTopEnabled: null
  });

  // Misc. $scope Getters
  $scope.getHeadlineTrustedHtml = function () {
    return $sce.trustAsHtml($scope.block.headline);
  };
  $scope.getLogoUrl = function () {
    return EmbeddedGivingUtil.getLogoUrl($scope.page, $scope.theme);
  };
  $scope.getPrimaryColor = function () {
    return EmbeddedGivingColors.getPrimary();
  };

  $scope.getDonationAmountRaw = function () {
    var rawAmt = void 0;
    if ($scope.MODEL && $scope.MODEL.items && $scope.MODEL.items.length) {
      rawAmt = $scope.MODEL.items[0].raw_final_price;
    }
    // base cases
    if (!rawAmt || rawAmt === 'Custom') {
      rawAmt = 0;
    }

    return rawAmt;
  };

  $scope.getFOTAmount = function () {
    var fotAmount = 0;
    if ($scope.MODEL && $scope.MODEL.fee_on_top_amount) {
      fotAmount = $scope.MODEL.fee_on_top_amount;
    }
    return fotAmount;
  };

  // Misc. $scope Setters
  $scope.setActiveView = function (view) {
    if (EmbeddedGiving.hasView(view)) {
      $scope.eg.activeView = view.path;
    }
  };

  $scope.setIsValidPayment = function (isValid) {
    $scope.eg.isValidPayment = !!isValid;
  };

  EmbeddedGivingMessenger.postMessageAbTesting();

  // TODO move all this business logic out of ctrl
  /* [START: Overriding frsDonationBlock ctrl code] */
  var autofillDonationForm = function autofillDonationForm(event) {
    return $timeout(function () {
      scDigitalWalletsService.onDigitalWalletsSubmit();

      var payerName = event.payerName,
          payerEmail = event.payerEmail,
          paymentMethod = event.paymentMethod;
      var billingDetails = paymentMethod.billing_details;
      var name = billingDetails.name,
          _billingDetails$addre = billingDetails.address,
          city = _billingDetails$addre.city,
          state = _billingDetails$addre.state,
          line1 = _billingDetails$addre.line1,
          line2 = _billingDetails$addre.line2,
          postalCode = _billingDetails$addre.postal_code;


      var splitName = payerName.split(' ');
      var firstName = splitName[0];
      var lastName = splitName[splitName.length - 1];

      // Fill in donor information if the donor hasn't already
      if (!$scope.META.member_first_name) {
        $scope.META.member_first_name = firstName;
      }
      if (!$scope.META.member_last_name) {
        $scope.META.member_last_name = lastName;
      }
      if (!$scope.MODEL.member_name) {
        $scope.MODEL.member_name = payerName;
      }
      if (!$scope.MODEL.member_email_address) {
        $scope.MODEL.member_email_address = payerEmail;
      }

      // Fill in Billing Information
      splitName = name.split(' ');
      firstName = splitName[0];
      lastName = splitName[splitName.length - 1];

      $scope.MODEL.billing_first_name = firstName;
      $scope.MODEL.billing_last_name = lastName;
      $scope.MODEL.billing_address1 = line1;
      $scope.MODEL.billing_address2 = line2;
      $scope.MODEL.billing_city = city;
      $scope.MODEL.billing_postal_code = postalCode;
      $scope.MODEL.billing_state = state;

      $scope.MODEL.payment.stripe.source = paymentMethod;

      $scope.FORM.$setSubmitted();
    });
  };

  $scope.runPreflight = function () {
    try {
      var results = {};

      _.forEach($scope.PREFLIGHT, function (fn, key) {
        results[key] = fn();
      });

      return $q.all(results);
    } catch (e) {
      bugsnagClient.notify(e, {
        metaData: {
          userData: _.omit($scope.MODEL, 'payment')
        }
      });

      return $q.reject({ status: 500, e: e });
    }
  };

  $scope.submitDigitalWallets = function (event) {
    // wrapping in a $timeout to force a separate change detection cycle tick
    autofillDonationForm(event).then(function () {
      return $timeout(function () {});
    }).then(function () {
      if (event.complete) {
        event.complete('success');
      }
      $scope.goDonationConfirmation();
      // $scope.embeddedGivingCheckout(); TODO: for donation-confirmation task.
    });
  };

  PayPalSDK.setAutoSubmitPayPalCommerceHandler(function () {
    $timeout(function () {}).then(function () {
      $scope.FORM.$setSubmitted();
      $scope.embeddedGivingCheckout();
    });
  });

  // filter out all billing address stuff EXCEPT for postal code
  function __filterRequiredOptionalErrors(errors) {
    var filterables = ['billing_country', 'billing_state', 'billing_city', 'billing_address1'];
    var requiredErrors = errors && errors.required; // only care about 'required' inputs
    var filteredErrors = [];

    if (requiredErrors && requiredErrors.length) {
      for (var i = 0; i < requiredErrors.length; i += 1) {
        if (filterables.indexOf(requiredErrors[i].$name) < 0) {
          filteredErrors.push(requiredErrors[i]);
        }
      }
    }

    // if no errors, then delete 'required' prop
    if (!filteredErrors.length) {
      delete errors.required;
    } else {
      errors.required = filteredErrors;
    }
  }

  $scope.embeddedGivingCheckout = function () {
    if ($scope.globalState.processing) {
      return undefined;
    }

    // See: https://classydev.atlassian.net/browse/CL-38468
    if ($scope.eg.showAchTerms() && _.get($scope, 'MODEL.payment.ach')) {
      $scope.MODEL.payment.ach.achTermsAccepted = true;
    }

    if (!$scope.organization.preventRecurringFrequencies()) {
      var donationLevel = $scope.block['donation-levels'];
      var isOtherInputPresent = donationLevel.some(function (level) {
        return level.amount === 'Custom' && level.display_on_page;
      });

      // If other input field is not present and none of the donation amount is selected,
      // Then throw error and focus on first donation amount.
      if ($scope.getDonationAmountRaw() < 1 && !isOtherInputPresent) {
        var level = donationLevel.filter(function (item) {
          return item.display_on_page;
        });
        $('#D-A' + level[0].amount).focus(); // yuck
        return false;
      }
    }

    if ($scope.FORM.amount && $scope.getDonationAmountRaw() > 1 && $scope.META.fixedDonationAmount && !$scope.preventRecurringFrequency) {
      $scope.FORM.amount.$setValidity('required', true);
      $scope.FORM.amount.$setValidity('min', true);
      $scope.FORM.amount.$setValidity('max', true);
    }
    // Subforms are not automatically set to submitted
    Object.keys($scope.FORM).filter(function (key) {
      return $scope.FORM[key] instanceof formDirective[0].controller;
    }).forEach(function (key) {
      $scope.FORM[key].$setSubmitted();
    });

    // egfa is optional, which means all of the nested form
    // inputs should not be required if egfa is false
    // if one of the form errors are for an input on this optional
    // field, yet egfa isnt required, then remove them and continue.
    if (!EmbeddedGiving.isRequiredFullAddress) {
      __filterRequiredOptionalErrors($scope.FORM.$error);
    }
    if (!_.isEmpty($scope.FORM.$error)) {
      var ccFields = ['token', 'expirationMonth', 'expirationYear', 'securityCode'];
      var fullErrorList = EmbeddedGivingUtil.getFormErrorCount($scope.FORM);
      // since we can't focus any fields within the credit card frame, filter them out and add a custom element instead
      $scope.META.errorList = fullErrorList.filter(function (error) {
        return !_.includes(ccFields, error.$name);
      });

      if ($scope.META.errorList.length !== fullErrorList.length) {
        $scope.META.errorList.push({ $name: 'credit card', $element: $('#cc-entry-el') });
      }

      // If credit card error is the only error and we are using digital wallets to pay
      // skip validation so the submission can go through
      if (!scDigitalWalletsService.isDigitalWalletSubmission && !PayPalSDK.isPayPalCommerceSubmission || !EmbeddedGivingUtil.hasValidDigitalWalletErrors($scope.META.errorList)) {
        $timeout(function () {
          // we will want to focus the 'other' text field which may not be visible
          if ($scope.META.errorList.find(function (error) {
            return error.$name === 'amount';
          })) {
            if ($scope.organization.preventRecurringFrequencies()) {
              $('#D-AOther')[0].click();
            }
            $('input[name="amount"]').focus();
          }

          $scope.META.focusFormErrorsLink = true;
        });

        scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
        return undefined;
      }
    }

    $scope.META.numberOfErrors = 0;
    $scope.eg.isCheckingOut = true;
    angular.element('[sc-autofocus]').removeAttr('sc-autofocus');
    $scope.runPreflight().then(function () {
      return recaptchaService.verifyRecaptcha('DONATE');
    }).then(function (recaptchaVerification) {
      // eslint-disable-next-line prefer-promise-reject-errors
      if (!recaptchaVerification) return Promise.reject('RECAPTCHA');

      if ($scope.MODEL.recur_until) {
        $scope.MODEL.recur_until = moment($scope.MODEL.recur_until).format('YYYY-MM-DD');
      }

      // only map if eg is active
      if ($scope.eg.dtd.isActive && $window.doublethedonation) {
        mapCheckoutDtD();
      }

      $scope.MODEL.answers = [].concat(_toConsumableArray($scope.campaign.current.questions.optional.filter(function (optionalQuestion) {
        return optionalQuestion.tag !== 'homephone';
      }).map(function (question) {
        var questionEl = $scope.FORM[question.id];
        var answerVal = questionEl && questionEl.$modelValue;

        return {
          question_id: question.id,
          question_type: question.type,
          answer: answerVal
        };
      })), _toConsumableArray($scope.campaign.current.questions.custom.map(function (question) {
        var questionEl = $scope.FORM['cq_' + question.id];
        var answerVal = questionEl && questionEl.$modelValue;

        if (question.type === 'text') {
          answerVal = EmbeddedGivingUtil.parseFroalaText(answerVal);
        }

        return {
          question_id: question.id,
          question_type: question.type,
          answer: answerVal
        };
      }))).filter(function (answer) {
        return answer.answer !== undefined && answer.answer !== null && answer.answer !== '';
      });

      $scope.MODEL.comment = EmbeddedGivingUtil.parseFroalaText($scope.MODEL.comment);

      var cSrc = scSourceCodes.newData;
      if (cSrc.c_src || cSrc.c_src2) {
        $scope.MODEL.c_src = [cSrc];
      }

      return EmbeddedGivingDAL.checkout($scope.campaign.current.id, $scope.MODEL);
    }).then(function (response) {
      EmbeddedGivingMessenger.postMessageDonationComplete();

      var data = response.data;

      $scope.globalState.redirecting = true;
      $scope.globalState.submitDisabled = true;

      scDigitalWalletsService.setIsLoaded(false);
      scDigitalWalletsService.setIsDigitalWalletsSubmission(false);

      // Move all this conversion type logic to DAL
      data.payment_type = EmbeddedGivingUtil.getPaymentTypeFromCheckoutResponse(data); // for analytics
      scAnalytics.track('transaction', { transaction: data });

      var conversionData = {
        amount: data.total_gross_amount,
        transactionId: data.id,
        raw_currency_code: data.raw_currency_code,
        digitalWalletsPresent: $scope.META.digitalWalletsEnabled,
        digitalWalletType: $scope.META.digitalWalletType,
        paymentSource: data.payment_source
      };

      if ($scope.MODEL.frequency !== 'one-time') {
        scAnalytics.track('recurring-donation/create/complete', conversionData);
      } else {
        scAnalytics.track('donation/create/complete', conversionData);
      }

      $rootScope.$broadcast('donation.success', response.status);

      if (response.status === 202) {
        $log.warn(response.data);
      }

      // Update EG DAL cache with things we care about
      EmbeddedGivingDAL.updateCache({
        email: data.member_email_address,
        currency: data.raw_currency_code,
        frequency: data.frequency,
        dedication: $scope.MODEL.dedication,
        checkoutTotal: conversionData.amount,
        transactionId: conversionData.transactionId,
        rawAmount: $scope.getDonationAmountRaw(),
        fotAmount: $scope.getFOTAmount()
      });

      $timeout(function () {
        $scope.goThankYou();
        $scope.eg.isCheckingOut = false;
      });
    }).catch(function (response) {
      scDigitalWalletsService.setIsDigitalWalletsSubmission(false);
      // If the error response comes back with a fixed_fot_percent attached
      // this means that the org has changed the percentage while the donor
      // was in the middle of checkout, an unlikely ocurrance, to say the least.
      if (_.get(response, 'data.fixed_fot_percent', false)) {
        // broadcast a bad request event so that the user can get a fresh new iFrame
        // and input their card information again (new total, new card). This should
        // be extremely rare (.00001% odds) and its cumbersomeness is warranted.
        $rootScope.$broadcast('donation.failure', 400);
        // return handleFixedFotUpdate(response.data.fixed_fot_percent);
      }
      if (response.status && response.status !== 504) {
        scBanner(response.data || 'Sorry, something went wrong', 'error', false);
      } else if (response.message) {
        scBanner(response.message, 'error', false);
      } else {
        scBanner('Your request timed out. Please try again soon.', 'error', false);
      }

      $rootScope.$broadcast('donation.failure', response.status);
      $log.error(response.data || response.message);

      $timeout(function () {
        $scope.eg.isCheckingOut = false;
      });

      return undefined;
    });

    return undefined;
  };
  /* [STOP: overriding frsDonationBlock ctrl code] */

  function mapCheckoutDtD() {
    try {
      var dtdJson = localStorage.getItem('doublethedonation');
      var dtdObj = dtdJson ? JSON.parse(dtdJson) : null;

      if (dtdObj && dtdObj.doublethedonation_status === 'found') {
        var companyId = dtdObj.doublethedonation_company_id;
        var companyName = $scope.eg.dtd.companyName;
        var enteredText = dtdObj.doublethedonation_entered_text;

        $scope.MODEL.employer_match = {
          employer_id: companyId,
          employer_name: companyName,
          employer_search_text: enteredText
        };
        SC.organization.doubleTheDonationChannel.companyId = companyId;
      } else {
        $scope.MODEL.employer_match = dtdObj.doublethedonation_entered_text ? { employer_search_text: dtdObj.doublethedonation_entered_text } : null;
        SC.organization.doubleTheDonationChannel.companyId = null;
      }
    } catch (e) {
      // error parsing json
    }
  }

  /* [START: 'go' functions, which are used to navigate EG] */
  $scope.goView = function (view) {
    if (EmbeddedGiving.hasView(view)) {
      EmbeddedGivingLocations.updateParam(view.param); // only updates if change
      $scope.setActiveView(view);
    }
  };

  /* Donation consists of 1...M nested partials. Hence we have 5 children 'go's for the Donation View
   * They are declared in-order here.
   * Use goDonation() is used when want to open donation view but don't want to set active donation partial */
  $scope.goDonation = function () {
    $scope.eg.showDonationConfirmation = false;
    $scope.goView(EmbeddedGiving.views.donation);
  };

  $scope.goDonationRoot = function () {
    $scope.eg.donationStep = 'root';
    $scope.META.showPaymentDetails = false; // used by eg-pay component
    $scope.eg.showDonationConfirmation = false;
    $scope.goDonation();
  };

  /* Only if $scope.shouldShowRecurringNudge() */
  $scope.goDonationRecurringNudge = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.recurringNudge, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'recurringNudge';
    $scope.META.showPaymentDetails = false; // used by eg-pay component
    $scope.goDonation();
  };

  $scope.goDonationOptions = function () {
    if ($scope.shouldShowDonationOptions()) {
      EmbeddedGivingAnalytics.track(EG_EVENTS.donationOptions, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
      $scope.eg.donationStep = 'donationOptions';
      $scope.META.showPaymentDetails = false;
      $scope.goDonation();
      // TODO abstract this logic into a service? or is logic custom per view
      $timeout(function () {
        var btnDonationOptions = $('#eg-btn-donation-options');
        if ($('#eg-btn-donation-options')) btnDonationOptions.focus();
      });
    } else if ($scope.eg.donationStep === 'root' || $scope.eg.donationStep === 'recurringNudge') {
      $scope.goDonationContact();
    } else {
      $scope.goDonationRoot();
    }
  };

  $scope.goDonationContact = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.contact, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'contact';
    $scope.META.showPaymentDetails = true; // sanity
    $scope.goDonation();
    $timeout(function () {
      var btnDonationContact = $('#eg-btn-donation-contact');
      if (btnDonationContact) btnDonationContact.focus();
    });
  };

  /* Only if EmbeddedGiving.isRequiredFullAddress */
  $scope.goDonationAddress = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.fullAddress, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'address';
    $scope.META.showPaymentDetails = true;
    $scope.goDonation();
  };

  $scope.goDonationPaymentMethods = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.donationList, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'paymentMethods';
    $scope.setIsValidPayment(false);
    $scope.META.showPaymentDetails = false;
    $scope.goDonation();
  };

  $scope.goDonationPaymentDetails = function () {
    $scope.eg.donationStep = 'paymentDetails';
    $scope.META.showPaymentDetails = true; // for payments second view -> false
    $scope.goDonation();
  };

  /* NOTE: This is INTENTIONALLY the same donation step as 'paymentDetails
   * This is because certain payment methods NEED to be in view (e.g. Stripe)
   * So the solution is to 'overlay' the donation confirmation view on top of the
   * donation payment details view. This is done in eg.pay.ng.html
   */
  // TODO refactor to "donationPaymentConfirmation"
  $scope.goDonationConfirmation = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.donationConfirmation, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.donationStep = 'paymentDetails'; // see NOTE above for why we keep paymentDetails
    $scope.META.showPaymentDetails = true; // sanity?
    $scope.goDonation();
    // if pay is ach then update 'payment.ach.achTermsAccepted' Do in embeddedGivingCheckout
    $scope.eg.showDonationConfirmation = true; // set after transition
  };

  $scope.goThankYou = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.thankYou, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.goView(EmbeddedGiving.views['thank-you']);
  };

  $scope.goFAQ = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.faq, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.goView(EmbeddedGiving.views.faq);
  };

  $scope.openFAQOverlay = function () {
    EmbeddedGivingAnalytics.track(EG_EVENTS.faq, EmbeddedGivingUtil.getAnalyticsEventData($scope.MODEL));
    $scope.eg.faqOverlay.isOpen = true;
  };

  $scope.closeFAQOverlay = function () {
    $scope.eg.faqOverlay.isOpen = false;
  };

  /* [START: Utility functions shared across controllers] */
  $scope.shouldShowRecurringNudge = function () {
    if (!$scope.eg.isRequiredRecurringNudge) {
      return false;
    }

    var frequency = $scope.MODEL && $scope.MODEL.frequency;
    var max = EmbeddedGivingUtil.getDonationAmountMax($scope.block);
    var min = EmbeddedGivingUtil.getDonationAmountMin($scope.block);
    var current = $scope.getDonationAmountRaw();

    if (!EmbeddedGivingRecurringNudge.isShowable(frequency, current, max, min)) {
      return false;
    }

    return true;
  };

  $scope.shouldShowDonationOptions = function () {
    var hasComments = $scope.campaign.current && !$scope.campaign.current.hide_donation_comments;
    var hasDtD = $scope.eg.dtd.isEnabled;
    var hasDedication = $scope.campaign.current && !$scope.campaign.current.hide_dedications;
    var hasDesignations = scCampaignsService.active.DESIGNATIONS_ENABLED && scCampaignsService.active.current.designations_count > 1;

    return hasDtD || hasComments || hasDedication || hasDesignations;
  };

  $scope.shouldShowDonationConfirmation = function () {
    return $scope.eg && $scope.eg.showDonationConfirmation && $scope.META && $scope.META.showPaymentDetails;
  };

  $scope.openLink = function (link, desc) {
    $window.open(link, desc);
  };

  $scope.$on('eg.donation-payment.is-valid', function (_event, data) {
    // execute digest cycle becasue $on is async
    $timeout(function () {
      $scope.setIsValidPayment(data && data.isValid);
    });
  });

  // TODO @leo we dont want to use a $scope.$on here, but i wanted to
  // tie into the DW ctrl logic and didnt want to take the time to abstract
  // it for this POC. we can follow up here.
  $scope.$on('eg.donation-payment.external-digital-wallet', function (_event, data) {
    $scope.submitDigitalWallets(data);
  });

  function disableForm() {
    if ($scope.FORM && $scope.FORM.$$controls) {
      _.forEach($scope.FORM.$$controls, function (ctrl) {
        if (ctrl && ctrl.$name) {
          $scope.FORM.$removeControl(ctrl);
        }
      });
    }
  }

  $scope.$on('eg-disable-form', disableForm);
  $scope.$on('sc-form-submit', disableForm); // only used in creating and editing.. i think, we need to exhaustively test

  // Conditionally bootstrap double the donation
  if (EmbeddedGivingUtil.hasDoubleTheDonation()) {
    scCampaignsService.active.getCampaignChannel(CHANNEL.DTD).then(function (resp) {
      var metadata = resp && resp[0] && resp[0].metadata || {};
      $scope.eg.dtd.isEnabled = !!metadata.employer_match_on_donation_page;
      $scope.eg.dtd.isActive = !!metadata.employer_match_preselect;
    }).catch(function () {
      // TODO how to handle error? EG doesn't do banners.
      // scBanner('There was an error fetching the campaign channel status.', 'error');
    });
  }

  // TODO @hvnt Init logic
  // - read params and set appropriate view..
  if (!EmbeddedGiving.isPreviewing) {
    // preview does not have param..? TODO
    var view = EmbeddedGivingLocations.getViewByParam(); // for readability instead of nesting fxn call
    $scope.setActiveView(view); // if no param, defaults to Manager's default view
  }

  $scope.processKeyEvent = function (event) {
    $log.debug(event.keyCode);
  };

  EmbeddedGivingMessenger.postMessage('open');

  $scope.$on('$destroy', function () {
    EmbeddedGivingMessenger.postMessageDonationState();
    scAnalytics.destroy();
  });
}]);
})();