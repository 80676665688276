(function(){
'use strict';

recaptchaService.$inject = ["$window", "$http", "scCampaignsService", "loadRecaptcha", "bugsnagClient"];
angular.module('classy').factory('recaptchaService', recaptchaService);

function recaptchaService($window, $http, scCampaignsService, loadRecaptcha, bugsnagClient) {
  var siteKey = $window.SC.recaptcha;

  function isRecaptchaEnabled() {
    return scCampaignsService.active.current.effective_recaptcha_settings === 'ENABLED';
  }

  function verifyRecaptcha(action) {
    if (!isRecaptchaEnabled()) {
      return Promise.resolve(true);
    }

    return loadRecaptcha.then(function ($recaptcha) {
      if (!$recaptcha) {
        // If recaptcha is not loaded, don't prevent the donor from transacting.
        return Promise.resolve(true);
      }

      return $recaptcha.execute(siteKey, { action: action }).then(function (token) {
        return $http({
          method: 'GET',
          url: '/frs-api/verifyRecaptcha',
          params: {
            token: token
          }
        });
      }).then(function (_ref) {
        var data = _ref.data;
        return data.isValid;
      }).catch(function (e) {
        bugsnagClient.notify(e);
        // Don't block if there's an error with creating the assessment
        return true;
      });
    });
  }

  return {
    verifyRecaptcha: verifyRecaptcha,
    isRecaptchaEnabled: isRecaptchaEnabled
  };
}
})();