(function(){
'use strict';

scCampaignModelFactory.$inject = ["$log", "$http", "$q", "CG_STATUSES", "EG_STATUSES", "scBanner", "scCampaignDefaultBlocks", "scObjectModel", "scCloneDeep", "scAnalytics", "scOrganizationsService"];
var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

/**
 * @kind factory
 * @name scCampaignModel
 *
 * @description
 * A campaign constructor with some common
 * prototype methods.
 */
angular.module('classy').factory('scCampaignModel', scCampaignModelFactory);

function scCampaignModelFactory($log, $http, $q, CG_STATUSES, EG_STATUSES, scBanner, scCampaignDefaultBlocks, scObjectModel, scCloneDeep, scAnalytics, scOrganizationsService) {
  function scCampaignModel(data) {
    scObjectModel.call(this);

    this.type = 'campaign';
    this.saved = scCloneDeep(data);
    this.current = scCloneDeep(data);

    this.HAS_FUNDRAISING = ['reg_w_fund', 'p2p'].includes(this.current.type);
    this.IS_TICKETED = ['reg_w_fund', 'ticketed'].includes(this.current.type);
    this.DESIGNATIONS_ENABLED = ['p2p', 'crowdfunding', 'donation'].includes(this.current.type);

    this.defaultBlocks = scCampaignDefaultBlocks(data.type);
  }

  scCampaignModel.prototype = _.create(scObjectModel.prototype, {
    constructor: scCampaignModel
  });

  scCampaignModel.prototype.update = update;
  scCampaignModel.prototype.publish = publish;
  scCampaignModel.prototype.unpublish = unpublish;
  scCampaignModel.prototype.deactivate = deactivate;
  scCampaignModel.prototype.reactivate = reactivate;
  scCampaignModel.prototype.getFundraisingTeams = getFundraisingTeams;
  scCampaignModel.prototype.getFundraisingPages = getFundraisingPages;
  scCampaignModel.prototype.refreshData = refreshData;
  scCampaignModel.prototype.getOverview = getOverview;
  scCampaignModel.prototype.getSupporterCounts = getSupporterCounts;
  scCampaignModel.prototype.getTicketTypes = getTicketTypes;
  scCampaignModel.prototype.getRegistrations = getRegistrations;
  scCampaignModel.prototype.getPromoCodes = getPromoCodes;
  scCampaignModel.prototype.getAvailablePromoCodes = getAvailablePromoCodes;
  scCampaignModel.prototype.isType = isType;
  scCampaignModel.prototype.isInactive = isInactive;
  scCampaignModel.prototype.multiCurrencyDisabled = multiCurrencyDisabled;
  scCampaignModel.prototype.getDesignations = getDesignations;
  scCampaignModel.prototype.getInternalName = getInternalName;
  scCampaignModel.prototype.updateDefaultDesignation = updateDefaultDesignation;
  // Campaign Channels
  scCampaignModel.prototype.getCampaignChannel = getCampaignChannel;
  scCampaignModel.prototype.postCampaignChannel = postCampaignChannel;
  scCampaignModel.prototype.putCampaignChannel = putCampaignChannel;
  scCampaignModel.prototype.putCampaignChannelObject = putCampaignChannelObject;
  // Crypto Giving
  scCampaignModel.prototype.hasCryptoGiving = hasCryptoGiving;
  scCampaignModel.prototype.enableCryptoGiving = enableCryptoGiving;
  scCampaignModel.prototype.disableCryptoGiving = disableCryptoGiving;
  // Embedded Giving
  scCampaignModel.prototype.getEmbeddedGiving = getEmbeddedGiving;
  scCampaignModel.prototype.setEmbeddedGiving = setEmbeddedGiving;
  scCampaignModel.prototype.hasEmbeddedGiving = hasEmbeddedGiving;
  scCampaignModel.prototype.hasWhitelistedUrl = hasWhitelistedUrl;
  scCampaignModel.prototype.hasUrlWhitelisted = hasUrlWhitelisted;
  scCampaignModel.prototype.removeWhitelistedUrl = removeWhitelistedUrl;
  scCampaignModel.prototype.setHideAnonymousDonation = setHideAnonymousDonation;
  scCampaignModel.prototype.whitelistUrl = whitelistUrl;
  // Exit Intent Modal
  scCampaignModel.prototype.hasExitIntentModal = hasExitIntentModal;
  // Intelligent Ask Amounts
  scCampaignModel.prototype.hasIntelligentAsk = hasIntelligentAsk;
  scCampaignModel.prototype.hasIntelligentAskOneTime = hasIntelligentAskOneTime;
  scCampaignModel.prototype.hasIntelligentAskRecurring = hasIntelligentAskRecurring;
  scCampaignModel.prototype.setIntelligentAsk = setIntelligentAsk;

  function update() {
    var _this = this;

    var updateBody = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var deferred = $q.defer();
    var data = {};
    var promises = [];
    if (_.isEmpty(updateBody)) {
      var changedKeys = this.mysqlDiff();

      _.forEach(changedKeys, function (key) {
        data[key] = _this.current[key];
      });

      // TODO: REMOVE THIS HACKY STUFF.
      if (!_.isEmpty(data.ended_at)) {
        data.started_at = this.current.started_at;
      }

      if (_.isEmpty(data)) {
        deferred.resolve({
          data: scCloneDeep(this.current)
        });
        return deferred.promise;
      }
    } else {
      data = updateBody;
    }

    promises.push($http({
      url: '/frs-api/campaign/' + this.current.id,
      method: 'PUT',
      data: data
    }));
    if (_.get(SC, 'organization.facebookChannel.status') && _.has(updateBody, 'allow_fb_fundraising_pages')) {
      if (updateBody.method === 'PUT') {
        promises.push(this.putCampaignChannel(updateBody.allow_fb_fundraising_pages, updateBody.channelId));
      } else {
        promises.push(this.postCampaignChannel(updateBody.channel, updateBody.allow_fb_fundraising_pages));
      }
    }

    $q.all(promises).then(function (res) {
      _.merge(_this.current, scCloneDeep(res[0].data));
      _this.saved = scCloneDeep(_this.current);
      deferred.resolve(res);
    }).catch(function (error) {
      deferred.reject(error);
    });
    return deferred.promise;
  }

  function publish() {
    var _this2 = this;

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/publish',
      method: 'POST'
    }).then(function (response) {
      // We only aim to change the status of saved in this function call
      _.merge(_this2.saved, scCloneDeep(response.data));
      _this2.current.status = _this2.saved.status;

      scAnalytics.track('campaign/updated', {
        property: 'published',
        new: true,
        old: false,
        campaign_id: _.get(response, 'data.id', null),
        organization_id: _.get(response, 'data.organization_id', null)
      });

      return response;
    });
  }

  function unpublish() {
    var _this3 = this;

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/unpublish',
      method: 'POST'
    }).then(function (response) {
      // We only aim to change the status of saved in this function call
      _.merge(_this3.saved, scCloneDeep(response.data));
      _this3.current.status = _this3.saved.status;

      scAnalytics.track('campaign/updated', {
        property: 'published',
        new: false,
        old: true,
        campaign_id: _.get(response, 'data.id', null),
        organization_id: _.get(response, 'data.organization_id', null)
      });

      return response;
    });
  }

  function reactivate() {
    var _this4 = this;

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/reactivate',
      method: 'POST'
    }).then(function (response) {
      // We only aim to change the status of saved in this function call
      _.merge(_this4.saved, scCloneDeep(response.data));
      _this4.current.status = _this4.saved.status;

      return response;
    });
  }

  function deactivate() {
    var _this5 = this;

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/deactivate',
      method: 'POST'
    }).then(function (response) {
      // We only aim to change the status of saved in this function call
      _.merge(_this5.saved, scCloneDeep(response.data));
      _this5.current.status = _this5.saved.status;

      return response;
    });
  }
  function getCampaignChannel(channel) {
    return $http({
      url: '/frs-api/campaigns/' + this.current.id + '/channels',
      method: 'GET',
      params: {
        filter: 'channel_name=' + channel
      }
    }).then(function (response) {
      return response.data.data;
    });
  }
  function postCampaignChannel(channelName, status) {
    return $http({
      url: '/frs-api/campaigns/' + this.current.id + '/channels',
      method: 'POST',
      data: {
        channel_name: channelName,
        status: status
      }
    });
  }
  function putCampaignChannel(status, channelId) {
    return $http({
      url: '/frs-api/campaign-channels/' + channelId,
      method: 'PUT',
      data: {
        status: status
      }
    });
  }

  function putCampaignChannelObject(channelDetails) {
    return $http({
      url: '/frs-api/campaign-channels/' + channelDetails.channelId,
      method: 'PUT',
      data: {
        employer_match_on_donation_page: channelDetails.employer_match_on_donation_page,
        employer_match_on_thankyou_page: channelDetails.employer_match_on_thankyou_page,
        employer_match_preselect: channelDetails.employer_match_preselect
      }
    });
  }

  function getFundraisingPages(query, cached) {
    var queryString = '',
        queryObj = {};

    if (_.isString(query)) {
      queryString = query;
    } else if (_.isPlainObject(query)) {
      queryObj = query;
    }

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/fundraising-pages' + queryString,
      method: 'GET',
      cache: cached,
      params: queryObj
    });
  }

  function getFundraisingTeams(query, cached) {
    var queryString = '',
        queryObj = {};

    if (_.isString(query)) {
      queryString = query;
    } else if (_.isPlainObject(query)) {
      queryObj = query;
    }

    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/fundraising-teams' + queryString,
      method: 'GET',
      cache: cached,
      params: queryObj
    });
  }

  // Helper function so that we can get the locally-stored overview data without always having to
  // request it from the server. If the overview data has not yet been requested, it'll trigger
  // a request for that.
  function getOverview() {
    if (this.current.overview && !_.isEmpty(this.current.overview)) {
      return $q.resolve(this.current.overview);
    }

    return this.refreshData();
  }

  function refreshData() {
    var _this6 = this;

    return $http({
      method: 'GET',
      url: '/frs-api/campaigns/' + this.current.id + '/overview'
    }).then(function (_ref) {
      var data = _ref.data;

      var updateableData = ['donation_net_amount', 'donors_count', 'fundraisers_count', 'fees_amount', 'gross_amount', 'total_gross_amount', 'net_amount', 'transactions_count', 'percent_to_goal'];
      var updatedData = _.pick(data, updateableData);

      // If we don't have an overview, set it manually.
      if (!_this6.current.overview) {
        _this6.current.overview = {};
      }

      // We want to mutate the object as we have it rather than overwriting it in case anyone
      // is using a watcher on the object.
      Object.assign(_this6.current.overview, updatedData);

      return _this6.current.overview;
    });
  }

  /**
   * Gets statistics for the campaign supporters.
   *
   * @return Promise<{
   *   supporterCount: number,
   *   captainCount: number,
   *   fundraiserCount: number,
   *   fundraiserAndCaptainCount: number,
   *   supporterCount: number
   * }>
   */

  function getSupporterCounts(includeChannelOverview) {
    var _this7 = this;

    var includeEngagement = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    var promises = [];

    promises.push($http.get('/frs-api/campaigns/' + this.current.id + '/overview?engagement=' + includeEngagement).then(function (response) {
      return response.data;
    }));

    if (!SC.campaignTemplatingView && SC.organization.facebookChannel.status && includeChannelOverview) {
      promises.push($http.get('/frs-api/campaigns/' + this.current.id + '/channels?filter=channel_name%3DFacebook').then(function (fbChannelData) {
        // check if FB channel data exist for the campaign and status is set to true
        if (fbChannelData.data && fbChannelData.data.data && fbChannelData.data.data.length && fbChannelData.data.data[0].status) {
          return $http.get('/frs-api/campaigns/' + _this7.current.id + '/channel-overview').then(function (response) {
            return response.data.unique_fundraisers_with_facebook;
          });
        }
        return Promise.resolve(null);
      }).catch(function (error) {
        scBanner('Oops! Something went wrong. Please try again.', 'error');
        return error;
      }));
    }

    return $q.all(promises).then(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          stats = _ref3[0],
          fbStats = _ref3[1];

      return {
        supporterCount: stats.unique_supporters || 0,
        donorCount: stats.donors_count || 0,
        fundraiserCount: stats.unique_fundraisers || 0,
        captainCount: stats.unique_captains || 0,
        fundraiserAndCaptainCount: stats.unique_captain_and_fundraisers || 0,
        attendeeCount: stats.unique_active_attendees || 0,
        facebookFundraiserCount: fbStats || 0,
        nonFacebookFundraiserCount: stats.unique_fundraisers - fbStats || 0
      };
    });
  }

  function getTicketTypes() {
    var filter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;

    return $http({
      url: '/frs-api/campaigns/' + this.current.id + '/ticket-types',
      method: 'GET',
      params: {
        per_page: 100,
        filter: filter
      }
    });
  }

  function getPromoCodes(params) {
    return $http({
      url: '/frs-api/campaigns/' + this.current.id + '/promo-codes',
      method: 'GET',
      params: params
    });
  }

  function getAvailablePromoCodes(params) {
    return $http({
      url: '/frs-api/campaigns/' + this.current.id + '/promo-codes/available',
      method: 'GET',
      params: params
    });
  }

  function getRegistrations() {
    return $http({
      url: '/frs-api/campaign/' + this.current.id + '/registrations',
      method: 'GET',
      params: {
        per_page: 1
      }
    });
  }

  function getInternalName() {
    return this.current.internal_name || this.current.name;
  }

  function isType(type) {
    return this.current.type === type;
  }

  function isInactive() {
    // Always show as active in templating view (CL-10918)
    if (SC.campaignTemplatingView) return false;

    return moment(this.current.ended_at) && moment().isAfter(moment(this.current.ended_at)) || this.current.status == 'deactivated';
  }

  function multiCurrencyDisabled() {
    var supportedTypes = ['crowdfunding', 'p2p', 'donation'];
    return !supportedTypes.includes(this.current.type) || scOrganizationsService.active.multiCurrencyDisabled() || _.isUndefined(this.current.raw_currency_code);
  }

  function getDesignations() {
    var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

    return $http({
      method: 'GET',
      url: '/frs-api/campaign/' + this.current.id + '/designations',
      params: {
        per_page: 100,
        page: page
      }
    });
  }

  function updateDefaultDesignation(designation) {
    this.current.designation = designation;
    this.current.designation_id = designation.id;
  }

  // [START: Crypto Giving]
  // __private setter fxn
  function __setCryptoGivingStatus(campaign, status) {
    // verify campaign is defined & that status (enum) is valid
    if (campaign && CG_STATUSES[status]) {
      // eslint-disable-next-line no-console
      console.log('[scCampaignModel:__setCryptoGivingStatus] status updated: ', status);
      campaign.crypto_giving = status;
    }
  }

  function hasCryptoGiving() {
    var cgStatus = this.current.crypto_giving;
    return cgStatus === CG_STATUSES.enabled;
  }

  function enableCryptoGiving() {
    __setCryptoGivingStatus(this.current, CG_STATUSES.enabled);
  }

  function disableCryptoGiving() {
    __setCryptoGivingStatus(this.current, CG_STATUSES.disabled);
  }
  // [STOP: Crypto Giving]

  // [START: Embedded Giving]
  function getEmbeddedGiving() {
    if (!this.current.embedded_giving) {
      return EG_STATUSES.disabled;
    }

    return this.current.embedded_giving;
  }

  function setEmbeddedGiving(status) {
    if (EG_STATUSES[status]) {
      // eslint-disable-next-line no-console
      console.log('[scCampaignModel:setEmbeddedGiving] status updated: ', status);
      this.current.embedded_giving = status;
    }
  }

  function hasEmbeddedGiving() {
    return !!(this.current.embedded_giving && this.current.embedded_giving.length && this.current.embedded_giving !== EG_STATUSES.disabled);
  }

  function whitelistUrl(url) {
    this.current.whitelist_url = this.current.whitelist_url || '';

    // If duplicate, ignore
    var urls = this.current.whitelist_url.split(' ');
    if (urls.indexOf(url) >= 0) {
      return this.current.whitelist_url;
    }

    if (!this.current.whitelist_url.length) {
      this.current.whitelist_url = url;
    } else {
      this.current.whitelist_url += ' ' + url;
    }

    return this.current.whitelist_url;
  }

  function removeWhitelistedUrl(url) {
    this.current.whitelist_url = this.current.whitelist_url || '';
    var urls = this.current.whitelist_url.split(' ');
    var urlIndex = urls.indexOf(url);

    if (urlIndex >= 0) {
      urls.splice(urlIndex, 1);
    }
    this.current.whitelist_url = urls.join(' ');

    return this.current.whitelist_url;
  }

  function hasWhitelistedUrl() {
    return this.current.whitelist_url && this.current.whitelist_url.length > 0;
  }

  function hasUrlWhitelisted(url) {
    if (!url || !url.length) {
      return false;
    }

    var whitelistedUrls = this.current.whitelist_url;
    var hasUrl = whitelistedUrls.indexOf(url) >= 0;
    return hasUrl;
  }
  // [STOP: Embedded Giving]

  // [START: Intelligent Ask]
  function hasIntelligentAsk() {
    return this.current && (this.current.use_intelligent_ask_onetime || this.current.use_intelligent_ask_recurring);
  }

  function hasIntelligentAskOneTime() {
    return this.current && this.current.use_intelligent_ask_onetime;
  }

  function hasIntelligentAskRecurring() {
    return this.current && this.current.use_intelligent_ask_recurring;
  }

  function setIntelligentAsk(data) {
    var config = data || {};
    if (_.has(config, 'oneTime')) {
      $log.info('[scCampaignModel:setIntelligentAsk] oneTime set: ', config.oneTime);
      this.current.use_intelligent_ask_onetime = !!config.oneTime;
    }

    if (_.has(config, 'recurring')) {
      $log.info('[scCampaignModel:setIntelligentAsk] recurring set: ', config.recurring);
      this.current.use_intelligent_ask_recurring = !!config.recurring;
    }
  }
  // [STOP: Intelligent Ask]

  function hasExitIntentModal() {
    return this.current && !!this.current.exit_modal;
  }

  function setHideAnonymousDonation(value) {
    this.current.hide_anonymous_donations = value;
  }

  return scCampaignModel;
}
})();